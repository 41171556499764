import {Injectable} from '@angular/core';
import {StorageService} from "@lib/services/storage.service";

@Injectable({providedIn: 'root'})
export class SessionStorage extends StorageService {

  protected readonly defaultError = `session storage is not available`;

  protected _isAvailable() {
    try {
      if (window.sessionStorage == undefined) {
        this.setError('Browser does not support session storage');
        console.warn(this.error);
        return false;
      }
      sessionStorage.setItem('__test__', 'test');
      sessionStorage.removeItem('__test__');
    } catch (e: any) {
      console.warn(`Can't access sessionStorage:`, e.message);
      this.setError('Session data storage has been disabled for this site');
      return false;
    }

    return true;
  }

  protected _write(key: string, value: string) {
    return sessionStorage.setItem(key, value);
  }

  protected _delete(key: string) {
    return sessionStorage.removeItem(key);
  }

  protected _read(key: string) {
    return sessionStorage.getItem(key);
  }

}
