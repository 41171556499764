import {inject, Injectable} from '@angular/core';
import {RefreshTokenAuthService, RefreshTokenData} from "@lib/services/refresh-token-auth.service";
import {LocalStorage} from "@lib/services/local-storage.service";
import {SessionStorage} from "@lib/services/session-storage.service";
import {UserScopeService} from "@lib/services/user-scope.service";
import {BehaviorSubject, distinctUntilChanged, Observable, skip} from "rxjs";
import {permanentCache} from "@juulsgaard/rxjs-tools";
import {first, map} from "rxjs/operators";
import {DialogService} from "@juulsgaard/ngx-material";

@Injectable()
export class StandaloneAuthService extends RefreshTokenAuthService {

  private static REFRESH_TOKEN_KEY = 'appRefreshToken';

  private readonly localStorage = inject(LocalStorage);
  private readonly sessionStorage = inject(SessionStorage);
  private readonly userScope = inject(UserScopeService);
  private readonly dialogService = inject(DialogService);

  readonly standalone = true;

  readonly tokenData$: Observable<RefreshTokenData | undefined>;

  readonly currentTokenData$: Observable<RefreshTokenData>;
  readonly currentRefreshToken$: Observable<string>;
  readonly currentUserId$: Observable<string>;

  private state$ = new BehaviorSubject<RefreshTokenData|undefined>(undefined);

  constructor() {
    super();

    this.tokenData$ = new Observable<RefreshTokenData | undefined>(subscriber => {

      const token = this.state$.value ?? this.getActiveSession();
      subscriber.next(token);

      return this.state$.pipe(skip(1)).subscribe(subscriber);
    }).pipe(distinctUntilChanged(), permanentCache());

    this.currentTokenData$ = this.tokenData$.pipe(
      first(),
      map(data => {
        if (!data) throw new Error('Not logged in');
        return data;
      })
    );

    this.currentRefreshToken$ = this.currentTokenData$.pipe(map(x => x.token));
    this.currentUserId$ = this.currentTokenData$.pipe(map(x => x.userId));
  }

  private getActiveSession() {

    // Look for an existing session
    let localToken = this.getSessionToken();

    // If valid session exists, use that token
    if (localToken) {
      this.userScope.joinScope(localToken.userId);
      return localToken;
    }

    // Look for an existing global session
    let globalToken = this.getGlobalToken();

    if (globalToken) {
      this.userScope.joinScope(globalToken.userId);
      return globalToken;
    }

    return undefined;
  }

  private getSessionToken(): RefreshTokenData | undefined {
    return this.readToken(this.sessionStorage, StandaloneAuthService.REFRESH_TOKEN_KEY);
  }

  private getGlobalToken(): RefreshTokenData | undefined {
    return this.readToken(this.localStorage, StandaloneAuthService.REFRESH_TOKEN_KEY);
  }

  async storeRefreshToken(refreshToken: string) {
    const data = this.parseToken(refreshToken);
    if (!data) return;

    await this.userScope.createScope(data.userId);

    this.sessionStorage.tryWrite(StandaloneAuthService.REFRESH_TOKEN_KEY, this.formatToken(data));
    this.localStorage.tryWrite(StandaloneAuthService.REFRESH_TOKEN_KEY, this.formatToken(data));
    this.state$.next(data);
  }

  invalidateToken() {
    console.warn("Invalidating Refresh Token");
    this.sessionStorage.tryDelete(StandaloneAuthService.REFRESH_TOKEN_KEY);
    this.localStorage.tryDelete(StandaloneAuthService.REFRESH_TOKEN_KEY);
    this.userScope.clearScope();
    this.state$.next(undefined);
  }

  async logOut() {
    const confirm = await this.dialogService.delete('Log Out', 'Are you sure you want to log out?', {deleteText: 'Log Out'});
    if (!confirm) return;

    this.invalidateToken();
    location.reload();
  }
}
