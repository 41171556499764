import {DestroyRef, inject, Injectable, NgZone} from '@angular/core';
import {UserScopeDatabase} from "@lib/databases/user-scope.database";
import {fromEvent, Subscription} from "rxjs";
import {LocalStorage} from "@lib/services/local-storage.service";

@Injectable({providedIn: 'root'})
export class UserScopeService {

  protected static readonly SCOPE_KEY = 'userDatabaseScope';

  private readonly database = inject(UserScopeDatabase);
  private readonly storage = inject(LocalStorage);
  private readonly zone = inject(NgZone);

  private localScope?: string;
  private sub?: Subscription;

  constructor() {
    inject(DestroyRef).onDestroy(() => this.sub?.unsubscribe());
  }

  joinScope(userId: string) {
    if (!this.storage.isAvailable()) return;

    const currentScope = this.storage.read(UserScopeService.SCOPE_KEY);

    if (!currentScope) {
      this.storage.write(UserScopeService.SCOPE_KEY, userId);
    } else if (currentScope !== userId) {
      return;
    }

    this.registerScope(userId);
  }

  async createScope(userId: string) {
    if (!this.storage.isAvailable()) return;
    const currentScope = this.storage.read(UserScopeService.SCOPE_KEY);

    if (currentScope !== userId) {
      this.database.enable();

      try {
        await this.database.delete();
        this.storage.write(UserScopeService.SCOPE_KEY, userId);
      } catch (e) {
        this.database.disable();
        throw e;
      }
    }

    this.registerScope(userId);
  }

  clearScope() {
    this.sub?.unsubscribe();
    this.sub = undefined;
    this.localScope = undefined;
    this.database.disable();
  }

  private registerScope(userId: string) {
    this.localScope = userId;
    this.database.enable();

    if (this.sub) return;
    this.sub = this.listenForChanges();
  }

  private listenForChanges() {
    return this.zone.runOutsideAngular(() =>
      fromEvent<StorageEvent>(window, 'storage')
        .subscribe(e => {

          if (!this.database.enabled) {
            this.sub?.unsubscribe();
            this.sub = undefined;
            return;
          }

          if (e.key !== UserScopeService.SCOPE_KEY) return;
          if (e.newValue === this.localScope) return;

          this.sub?.unsubscribe();
          this.sub = undefined;
          this.database.disable();
        })
    );
  }
}
